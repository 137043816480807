import { useRef } from 'react';
import { SliderSection, SlideComponent } from '@/components/SliderSection';

import firstSlideVideo from '@/assets/images/creator/dream.mp4';
import secondSlideVideo from '@/assets/images/creator/music.mp4';
import thirdSlideVideo from '@/assets/images/creator/monetize.mp4';
import fourthSlideVideo from '@/assets/images/creator/artist.mp4';
import previewBg from '@/assets/images/creator/creatorSlideBg.png';

import styles from './style.module.css';

const slides = [
  {
    key: 1,
    isPreview: true,
    label: 'OUTDREAM YOURSELF',
    imageUrl: previewBg
  },
  {
    key: 2,
    src: firstSlideVideo,
    label: 'Create your dream projects with access to your favorite artists'
    // desc: 'Breathe life into your dream projects with access to all your favourite artists.'
  },
  {
    key: 3,
    src: secondSlideVideo,
    label: 'Transform your music with access to an abundance of vocal talent'
    // desc: 'Breathe life into your dream projects with access to all your favourite artists.'
  },
  {
    key: 4,
    src: thirdSlideVideo,
    label: 'Monetize AI music legally'
    // desc: 'Breathe life into your dream projects with access to all your favourite artists.'
  },
  {
    key: 5,
    src: fourthSlideVideo,
    label: 'Drive the careers of next&nbsp;gen AI artists'
    // desc: 'Breathe life into your dream projects with access to all your favourite artists.'
  }
];

export const Creator = () => {
  const slider = useRef(null);

  return (
    <div
      ref={slider}
      id="creators"
      className={`section flex-center scroll-section ${styles.sliderContainer}`}
    >
      <div className="section-container">
        <SliderSection
          sectionName="creators"
          slides={slides}
          sliderRef={slider}
          SlideComponent={SlideComponent}
          hasVideo
        />
      </div>
    </div>
  );
};
