export const sliderCss = `
.swiper-wrapper {
  max-width: 1200px;
}

.swiper-slide {
  aspect-ratio: auto;
}

.swiper-pagination {
  position: absolute;
  z-index: 3;
  right: 48px;
  bottom: 48px;
  display: flex;
  column-gap: 12px;
}

.swiper-pagination-bullet {
  background-color: var(--dark-300);
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet-active {
  background-color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

@media (max-width: 900px) {
  .swiper-pagination {
    right: 24px;
    bottom: 24px;
  }
}

@media (max-width: 768px) {
  .swiper-wrapper {
    height: 100%;
  }
}`;
