import {
  HeroSection,
  WhatWeDoSection,
  Details1,
  Details2,
  Artists,
  Creator,
  Labels,
  // WelcomeTo1,
  WelcomeTo2,
  CaseStudy,
  DemoScreen
} from '@/components';

import '@/assets/styles/global.css';

export const LandingContent = ({ playButtons, cardIcon }) => {
  return (
    <>
      <HeroSection />

      <WhatWeDoSection cardIcon={cardIcon} />

      {/*<WelcomeTo1 />*/}
      <WelcomeTo2 />

      <CaseStudy playButtons={playButtons} />

      <DemoScreen />

      <Details1 />
      <Details2 cardIcon={cardIcon} />

      <Creator />

      <Artists />

      <Labels />
    </>
  );
};
