import { useRef } from 'react';
import { SliderSection, SlideComponent } from '@/components/SliderSection';

import firstSlideVideo from '@/assets/images/artists/build.mp4';
import secondSlideVideo from '@/assets/images/artists/collaborate.mp4';
import thirdSlideVideo from '@/assets/images/artists/grow.mp4';
import fourthSlideVideo from '@/assets/images/artists/protect.mp4';
import previewBg from '@/assets/images/artists/artistSliderBg.png';

import styles from './style.module.css';

const slides = [
  {
    key: 1,
    isPreview: true,
    label: 'EMPOWER YOUR VOICE',
    imageUrl: previewBg
  },
  {
    key: 2,
    src: firstSlideVideo,
    label: 'Build and share your musical avatar with the world'
    // desc: 'Build your musical avatar and share your voice with a world of creators and grow your global fan base, knowing you’ll always have creative control, remuneration and attribution over your AI voice.'
  },
  {
    key: 3,
    src: secondSlideVideo,
    label: 'Collaborate with other artists and creators'
    // desc: 'Build your musical avatar and share your voice with a world of creators and grow your global fan base, knowing you’ll always have creative control, remuneration and attribution over your AI voice.'
  },
  {
    key: 4,
    src: thirdSlideVideo,
    label: 'Grow your music catalogue'
    // desc: 'Build your musical avatar and share your voice with a world of creators and grow your global fan base, knowing you’ll always have creative control, remuneration and attribution over your AI voice.'
  },
  {
    key: 5,
    src: fourthSlideVideo,
    label: 'Protect your voice and likeness'
    // desc: 'Build your musical avatar and share your voice with a world of creators and grow your global fan base, knowing you’ll always have creative control, remuneration and attribution over your AI voice.'
  }
];

export const Artists = () => {
  const slider = useRef(null);

  return (
    <div
      ref={slider}
      id="artists"
      className={`section flex-center artists scroll-section ${styles.section}`}
    >
      <div className="section-container">
        <SliderSection
          sectionName="artists"
          slides={slides}
          sliderRef={slider}
          SlideComponent={SlideComponent}
          hasVideo
        />
      </div>
    </div>
  );
};
