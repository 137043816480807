import { useEffect, useRef, useState } from 'react';
import firstSlideImage from '@/assets/images/4cs-of-hooky/control.png';
import secondSlideImage from '@/assets/images/4cs-of-hooky/compensation.png';
import thirdSlideImage from '@/assets/images/4cs-of-hooky/credits.png';
import fourthSlideImage from '@/assets/images/4cs-of-hooky/creativity.png';
import sectionBg from '@/assets/images/4cs-of-hooky/4cs-of-hooky.png';
import dropsTopBg from '@/assets/images/4cs-of-hooky/drops-top.png';
import dropsBottomBg from '@/assets/images/4cs-of-hooky/drops-bottom.png';

import styles from './style.module.css';
import slideStyles from '../WhatWeDo/style.module.css';
import { SLIDER_PARAMS } from '../WhatWeDo/WhatWeDoSection';

const slides = [
  {
    key: 11,
    imageUrl: firstSlideImage.src,
    label: 'Artists are in',
    labelWord: 'CONTROL',
    desc: 'Hooky is built by creators for creators, and the best interest of the artist is at the heart of everything we do. Artists will always have control over use of their voice, name and likeness on all creative works—including the opportunity to decline use of their voice in AI projects.'
  },
  {
    key: 12,
    imageUrl: secondSlideImage.src,
    label: 'Artists are fairly compensated',
    labelWord: 'COMPENSATION',
    desc: 'Know your worth. Set your worth. At Hooky, artists are equitably compensated for any approved use of their voice, name and likeness. We prioritize the artist, allowing them to determine splits based on what they believe their participation is worth.'
  },
  {
    key: 13,
    imageUrl: thirdSlideImage.src,
    label: '100% transparent',
    labelWord: 'CREDITS',
    desc: 'With nothing to hide, you have nothing to fear. Voice AI attribution should be clearly labelled for listeners, so they know if it’s the actual artist performing or an AI voice model. We advocate for full transparency, so the industry and fans can rest assured knowing the artist approved the song creatively and is being fairly compensated.'
  },
  {
    key: 14,
    imageUrl: fourthSlideImage.src,
    label: 'We protect human',
    labelWord: 'CREATIVITY',
    desc: 'We use AI for good, not for greed. Everything that Hooky does across all aspects of our business, including voice AI model training, is grounded in ethics and respecting artists rights, owners and copyright.'
  },
  {
    key: 15,
    imageUrl: firstSlideImage.src,
    label: 'Artists are in',
    labelWord: 'CONTROL',
    desc: 'Hooky is built by creators for creators, and the best interest of the artist is at the heart of everything we do. Artists will always have control over use of their voice, name and likeness on all creative works—including the opportunity to decline use of their voice in AI projects.'
  },
  {
    key: 16,
    imageUrl: secondSlideImage.src,
    label: 'Artists are fairly compensated',
    labelWord: 'COMPENSATION',
    desc: 'Know your worth. Set your worth. At Hooky, artists are equitably compensated for any approved use of their voice, name and likeness. We prioritize the artist, allowing them to determine splits based on what they believe their participation is worth.'
  },
  {
    key: 17,
    imageUrl: thirdSlideImage.src,
    label: '100% transparent',
    labelWord: 'CREDITS',
    desc: 'With nothing to hide, you have nothing to fear. Voice AI attribution should be clearly labelled for listeners, so they know if it’s the actual artist performing or an AI voice model. We advocate for full transparency, so the industry and fans can rest assured knowing the artist approved the song creatively and is being fairly compensated.'
  },
  {
    key: 18,
    imageUrl: fourthSlideImage.src,
    label: 'We protect human',
    labelWord: 'CREATIVITY',
    desc: 'We use AI for good, not for greed. Everything that Hooky does across all aspects of our business, including voice AI model training, is grounded in ethics and respecting artists rights, owners and copyright.'
  }
];

export const Details1 = () => {
  return (
    <div id="details1" className={`section scroll-section ${styles.imageText} `}>
      <div>
        <h2 className={styles.imageTitle}>
          <span>4 C'S OF</span>
          <span>HOOKY</span>
        </h2>

        <h3 className={`${styles.imageSubtitle} ${styles.show}`}>OUR CORE BELIEFS</h3>
      </div>

      <img
        className={`sectionImageBg ${styles.show}`}
        src={sectionBg.src}
        alt="Background section"
        draggable="false"
      />

      <img
        className={styles.sectionAdditionTopBg}
        src={dropsTopBg.src}
        alt="Drops"
        draggable="false"
      />

      <img
        className={styles.sectionAdditionBottomBg}
        src={dropsBottomBg.src}
        alt="Drops"
        draggable="false"
      />
    </div>
  );
};

export const Details2 = ({ cardIcon }) => {
  const slider = useRef(null);
  const swiperRef = useRef(null);
  const [detailsCard, setDetailsCard] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    Object.assign(swiperRef.current, SLIDER_PARAMS);

    try {
      swiperRef.current?.initialize();
    } catch (err) {
      setHasError(true);
    }
  }, []);

  const handleWheelChange = (event) => {
    if (document.body.clientWidth > 1700) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.wheelDelta && event.wheelDelta > 0) {
      swiperRef.current?.swiper.slidePrev();

      return;
    }

    swiperRef.current?.swiper.slideNext();
  };

  useEffect(() => {
    slider.current?.addEventListener('wheel', handleWheelChange, true);

    return () => {
      slider.current?.removeEventListener('wheel', handleWheelChange, true);
    };
  }, []);

  return (
    <div id="details2" className={`section spaceTop scroll-section ${styles.section}`}>
      <h2 className="section-title text-center">THE 4 C’S OF HOOKY</h2>
      <p className={styles.subtitle}>
        We understand AI is great, but humans will always come first.
      </p>

      {hasError ? (
        <div className={`section-container ${styles.withoutSlider}`}>
          {slides.map((slide) => {
            const handleClick = () => {
              if (detailsCard === slide.key) {
                setDetailsCard('');

                return;
              }

              setDetailsCard(slide.key);
            };

            return (
              <div key={slide.key}>
                <div className={`${slideStyles.slide} ${styles.slide} ${
                    detailsCard === slide.key ? `${slideStyles.hover} ${styles.hover}` : ''
                  }`}
                >
                  <img src={slide.imageUrl} alt="slide image" />

                  <button type="button" onClick={handleClick}>
                    {cardIcon}
                  </button>

                  <div className={slideStyles.text}>
                    <h3 className={styles.title}>{slide.label}</h3>
                    <h3 className={styles.titleWord}>{slide.labelWord}</h3>
                    <p className={slideStyles.desc}>{slide.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="section-container" ref={slider}>
          <swiper-container
            init="false"
            ref={swiperRef}
          >
            {slides.map((slide) => {
              const handleClick = () => {
                if (detailsCard === slide.key) {
                  setDetailsCard('');

                  return;
                }

                setDetailsCard(slide.key);
              };

              return (
                <swiper-slide key={slide.key}>
                  <div
                    key={slide.key}
                    className={`${slideStyles.slide} ${styles.slide} ${
                      detailsCard === slide.key ? `${slideStyles.hover} ${styles.hover}` : ''
                    }`}
                  >
                    <img src={slide.imageUrl} alt="slide image" />

                    <button type="button" onClick={handleClick}>
                      {cardIcon}
                    </button>

                    <div className={slideStyles.text}>
                      <h3 className={styles.title}>{slide.label}</h3>
                      <h3 className={styles.titleWord}>{slide.labelWord}</h3>
                      <p className={slideStyles.desc}>{slide.desc}</p>
                    </div>
                  </div>
                </swiper-slide>
              );
            })}
          </swiper-container>
        </div>
      )}
    </div>
  );
};
