import sectionBg from '@/assets/images/case-study-bg.png';
import songPreview from '@/assets/images/song-preview.png';
import { CaseStudyModalBtn } from './CaseStudyModalBtn';
import styles from './styles.module.css';

export const CaseStudy = ({ playButtons }) => {
  return (
    <div
      id="caseStudy"
      className={`section scroll-section ${styles.caseStudy} ${styles.showContent}`}
    >
      <div className={`sectionImageBg`} style={{ backgroundImage: `url(${sectionBg.src})` }} />

      <div className={`section-container ${styles.showContent}`}>
        <div className={styles.leftBlock}>
          <h2 className="section-title">
            Case study:
            <span>LAUV</span>
          </h2>

          <div className={`section-text ${styles.text}`}>
            <p>
              Hooky recently worked with LAUV to translate his hit single ‘Love U Like That’ to
              Korean.
            </p>

            <p>
              The result:
              <br />A sonically identical version of the song sung in Korean, with perfect clarity,
              diction and pronunciation.
            </p>
          </div>

          <CaseStudyModalBtn />
        </div>

        <div className={styles.rightBlock}>
          <div className={styles.cardPlay}>
            <img className={styles.cardPlayImage} src={songPreview.src} alt="Song preview" />

            <div>
              <h3 className={styles.cardPlayTitle}>Love U Like That</h3>
              <h4 className={styles.cardPlaySubtitle}>LAUV</h4>

              {playButtons}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
