import { useEffect } from 'react';
import verticalLogo from '@/assets/images/vertical-logo.svg';
import heroVideo from '@/assets/images/heroVideo.mp4';
import heroVideoMob from '@/assets/images/heroVideo-mob.mp4';
import styles from './style.module.css';
import { scrollToSection } from '@/utils/scrollToSection';
import { useMediaQuery } from '@/hooks';

export const HeroSection = () => {
  const isPhone = useMediaQuery('(max-width: 769px)');

  const handleEndVideo = async (event) => {
    try {
      await event.target.play();
    } catch (e) {
      console.error("Video is not supported in your browser");
    }

    const logo = document.querySelector('.logoLink');

    if (!logo?.classList.contains('show')) {
      logo?.classList.add('show');
    }
  };

  useEffect(() => {
    const video = document.querySelector('#heroVideo');

    video.addEventListener('ended', handleEndVideo);

    return () => {
      video.removeEventListener('ended', handleEndVideo, true);
    };
  }, []);

  const handleNextSection = () => {
    scrollToSection('whatWeDo');
  };

  const videoSrc = !isPhone ? heroVideo : heroVideoMob;

  return (
    <div id="hero" className={`section scroll-section ${styles.hero}`}>
      <video
        id="heroVideo"
        src={videoSrc}
        className={styles.heroVideo}
        autoPlay
        muted
        playsInline
      />

      <div>
        <h1 className="title-md">Distribute your music</h1>
      </div>

      <div className="sectionImageBg" />

      <div id="scroll-down-box" className={styles.scrollBtn}>
        <button id="scroll-down" onClick={handleNextSection}>
          <img src={verticalLogo.src} alt="Scroll down" />
        </button>

        <span>SCROLL DOWN</span>
      </div>
    </div>
  );
};
