import styles from './style.module.css';

export const SlideComponent = (props) => {
  const { isPreview, imageUrl, label, src, desc } = props;

  return (
    <>
      {isPreview ? (
        <div className={styles.preview} style={{ backgroundImage: `url(${imageUrl.src})` }}>
          <h2 className={styles.previewTitle}>{label}</h2>
        </div>
      ) : (
        <div className={styles.slideContent}>
          <div className={styles.video}>
            <video data-video-slide="video-slide" src={src} muted loop playsInline />
          </div>

          <div>
            <h3 className={styles.textBoxTitle} dangerouslySetInnerHTML={{ __html: label }} />
            {desc ? <p className={styles.textBoxDesc}>{desc}</p> : null}
          </div>
        </div>
      )}
    </>
  );
};
