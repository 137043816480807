export const CaseStudyModalBtn = () => {
  return (
    <a
      href="https://youtu.be/2aIUAOGIQYc?si=r1HvwzFwg86aoQss"
      className="button button-outline"
      target="_blank"
    >
      MORE INFO
    </a>
  );
};
