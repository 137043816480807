import firstSlideImage from '../../../assets/images/what-we-do/create-music.png';
import secondSlideImage from '../../../assets/images/what-we-do/distribute.png';
import thirdSlideImage from '../../../assets/images/what-we-do/license.png';
import fourthSlideImage from '../../../assets/images/what-we-do/analyze.png';
import styles from './style.module.css';
import { useEffect, useRef, useState } from 'react';

const SLIDERS = [
  {
    key: 1,
    imageUrl: firstSlideImage.src,
    label: 'Create music using your favorite artist’s voice',
    desc: 'Get ethical access to high-quality AI models from real artists for your music projects.'
  },
  {
    key: 2,
    imageUrl: thirdSlideImage.src,
    label: 'License your creations with real artists',
    desc: 'Build your musical avatar, while knowing you have 100% control over your voice, name and likeness on all AI collaborative works - meaning you can approve or deny any AI project that features your voice.'
  },
  {
    key: 3,
    imageUrl: secondSlideImage.src,
    label: 'Distribute your music globally',
    desc: 'Share your creations with the world. Get your music distributed to all major streaming platforms and ensure that you and all your collaborators are paid fairly.'
  },
  {
    key: 4,
    imageUrl: fourthSlideImage.src,
    label: 'Analyze your fan base and get paid',
    desc: 'Hooky allows you to measure fan engagement by effortlessly keeping track of how well your music is performing and who is listening.'
  },
  {
    key: 5,
    imageUrl: firstSlideImage.src,
    label: 'Create music using your favorite artist’s voice',
    desc: 'Get ethical access to high-quality AI models from real artists for your music projects.'
  },
  {
    key: 6,
    imageUrl: thirdSlideImage.src,
    label: 'License your creations with real artists',
    desc: 'Build your musical avatar, while knowing you have 100% control over your voice, name and likeness on all AI collaborative works - meaning you can approve or deny any AI project that features your voice.'
  },
  {
    key: 7,
    imageUrl: secondSlideImage.src,
    label: 'Distribute your music globally',
    desc: 'Share your creations with the world. Get your music distributed to all major streaming platforms and ensure that you and all your collaborators are paid fairly.'
  },
  {
    key: 8,
    imageUrl: fourthSlideImage.src,
    label: 'Analyze your fan base and get paid',
    desc: 'Hooky allows you to measure fan engagement by effortlessly keeping track of how well your music is performing and who is listening.'
  }
];

export const SLIDER_PARAMS = {
  slidesPerView: 3.5,
  spaceBetween: 25,
  navigation: false,
  pagination: false,
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1.2,
      spaceBetween: 15
    },
    760: {
      slidesPerView: 1.5,
      spaceBetween: 25
    },
    900: {
      slidesPerView: 2.2
    },
    1100: {
      slidesPerView: 2.5
    },
    1300: {
      slidesPerView: 3.5
    },
    1700: {
      slidesPerView: 4
    }
  }
};

export const WhatWeDoSection = ({ cardIcon }) => {
  const slider = useRef(null);
  const swiperRef = useRef(null);
  const [detailsCard, setDetailsCard] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    Object.assign(swiperRef.current, SLIDER_PARAMS);

    try {
      swiperRef.current?.initialize();
    } catch (err) {
      setHasError(true);
    }
  }, []);

  const handleWheelChange = (event) => {
    if (document.body.clientWidth > 1700) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.wheelDelta && event.wheelDelta > 0) {
      swiperRef.current?.swiper.slidePrev();

      return;
    }

    swiperRef.current?.swiper.slideNext();
  };

  useEffect(() => {
    slider.current?.addEventListener('wheel', handleWheelChange, true);

    return () => {
      if (hasError) {
        return;
      }
      slider.current?.removeEventListener('wheel', handleWheelChange, true);
    };
  }, []);

  return (
    <div id="whatWeDo" className={`section spaceTop scroll-section ${styles.section}`}>
      <h2 className="section-title text-center">WHAT WE DO</h2>

      {hasError ? (
        <div className={`section-container ${styles.withoutSlider}`}>
          {SLIDERS.map((slide) => {
            const handleClick = () => {
              if (detailsCard === slide.key) {
                setDetailsCard('');

                return;
              }

              setDetailsCard(slide.key);
            };

            return (
              <div key={slide.key}>
                <div className={`${styles.slide} ${detailsCard === slide.key ? styles.hover : ''}`}>
                  <img src={slide.imageUrl} alt="slide image" />

                  <button type="button" onClick={handleClick}>
                    {cardIcon}
                  </button>

                  <div className={styles.text}>
                    <h3 className={styles.title}>{slide.label}</h3>
                    <p className={styles.desc}>{slide.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="section-container" ref={slider}>
          <swiper-container init="false" ref={swiperRef}>
            {SLIDERS.map((slide) => {
              const handleClick = () => {
                if (detailsCard === slide.key) {
                  setDetailsCard('');

                  return;
                }

                setDetailsCard(slide.key);
              };

              return (
                <swiper-slide key={slide.key}>
                  <div
                    className={`${styles.slide} ${detailsCard === slide.key ? styles.hover : ''}`}
                  >
                    <img src={slide.imageUrl} alt="slide image" />

                    <button type="button" onClick={handleClick}>
                      {cardIcon}
                    </button>

                    <div className={styles.text}>
                      <h3 className={styles.title}>{slide.label}</h3>
                      <p className={styles.desc}>{slide.desc}</p>
                    </div>
                  </div>
                </swiper-slide>
              );
            })}
          </swiper-container>
        </div>
      )}
    </div>
  );
};
