import sectionBg2Drops from '../../../assets/images/welcome-to/welcome-to2-drops.png';
import sectionBg2 from '../../../assets/images/welcome-to/welcome-to2.png';
import sectionBg3 from '../../../assets/images/welcome-to/welcome-to3.png';

import styles from './style.module.css';

export const WelcomeTo1 = () => {
  return (
    <div id="welcomeTo1" className={`section scroll-section ${styles.containerWelcome}`}>
      <h2 className={`${styles.bigTitle} ${styles.showText}`}>
        <span>FREE</span>
        <span>YOUR</span>
        <span>VOICE</span>
      </h2>

      <div
        className={`sectionImageBg ${styles.showText}`}
        style={{ backgroundImage: `url(${sectionBg2.src})` }}
      />
      <div
        className={`${styles.dropsBg} ${styles.showText}`}
        style={{ backgroundImage: `url(${sectionBg2Drops.src})` }}
      />
    </div>
  );
};

export const WelcomeTo2 = () => {
  return (
    <div id="welcomeTo2" className={`section scroll-section ${styles.containerWelcome2}`}>
      <div className={`sectionImageBg`} style={{ backgroundImage: `url(${sectionBg3.src})` }} />

      <div className={`section-container ${styles.container}`}>
        <div className={`${styles.textBox}`}>
          <div className={styles.subtitle}>WELCOME TO HOOKY</div>

          <h2 className={styles.title}>A music licensing platform for AI voice modelling</h2>

          <p className={styles.text}>
            The convergence of human creativity and AI in the music industry has begun, promising a
            future where artistry and technology will unveil thrilling opportunities for the music
            industry to grow and evolve.
          </p>

          <p className={styles.text}>
            That's why we collaborated with the biggest record labels and created an unparalleled
            end-to-end solution that aligns the interests of labels, artists, producers and
            creators—unlocking a multiverse of creative possibilities.
            <span>Join us and find greatness.</span>
          </p>

          <a
            href="/pricing"
            className="button button-primary subscribeBtn"
          >
            SIGN UP
          </a>
        </div>
      </div>
    </div>
  );
};
