import musicIcon from '@/assets/images/demo-screen/music.svg';
import translateIcon from '@/assets/images/demo-screen/translate.svg';
import gamesIcon from '@/assets/images/demo-screen/games.svg';
import movieIcon from '@/assets/images/demo-screen/movie.svg';
import adsIcon from '@/assets/images/demo-screen/ads.svg';
import demoUrl from '@/assets/images/demo-screen/demo.png';

import styles from './style.module.css';

export const DemoScreen = () => {
  return (
    <div id="demoScreen" className={`section scroll-section ${styles.section}`}>
      <div className="section-container">
        <h2 className={`section-title ${styles.sectionTitle}`}>POWERING INDUSTRIES</h2>

        <ul className={styles.iconList}>
          <li>
            <img src={musicIcon.src} alt="Music" />
            MUSIC
          </li>

          <li>
            <img src={translateIcon.src} alt="Translate" />
            TRANSLATION
          </li>

          <li>
            <img src={gamesIcon.src} alt="Games" />
            GAMES
          </li>

          <li>
            <img src={movieIcon.src} alt="Movie" />
            MOVIES
          </li>

          <li>
            <img src={adsIcon.src} alt="Ads" />
            ADVERTISING
          </li>
        </ul>

        <div className={styles.demoImg}>
          <img src={demoUrl.src} alt="Demo app" />
        </div>
      </div>
    </div>
  );
};
