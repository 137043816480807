import { useEffect, useRef } from 'react';
import { SliderSection, SlideComponent } from '@/components/SliderSection';

import firstSlideVideo from '@/assets/images/labels/market.mp4';
import secondSlideVideo from '@/assets/images/labels/enterprise.mp4';
import thirdSlideVideo from '@/assets/images/labels/access.mp4';
import fourthSlideVideo from '@/assets/images/labels/discover.mp4';
import previewBg from '@/assets/images/labels/labelsBg.png';

import styles from './style.module.css';
import { Footer } from '@/components/sections/Labels/Footer';

const slides = [
  {
    key: 1,
    isPreview: true,
    label: 'SEIZE THE FUTURE',
    imageUrl: previewBg
  },
  {
    key: 2,
    src: firstSlideVideo,
    label: 'Expand market share'
    // desc: 'Join the renaissance of AI that will open the music industry to new creative frontiers, fresh talent and untapped revenue streams.'
  },
  {
    key: 3,
    src: secondSlideVideo,
    label: 'Integrate AI ethically and legally into your business'
    // desc: 'Join the renaissance of AI that will open the music industry to new creative frontiers, fresh talent and untapped revenue streams.'
  },
  {
    key: 4,
    src: thirdSlideVideo,
    label: 'Access new, undiscovered talent'
    // desc: 'Join the renaissance of AI that will open the music industry to new creative frontiers, fresh talent and untapped revenue streams.'
  },
  {
    key: 5,
    src: fourthSlideVideo,
    label: 'Discover a more convenient, efficient and scalable solution for voice over work'
    // desc: 'Join the renaissance of AI that will open the music industry to new creative frontiers, fresh talent and untapped revenue streams.'
  }
];

export const Labels = () => {
  const slider = useRef(null);

  const handleScrollSection = (event) => {
    if (event.deltaY > 0) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (slider.current) {
      // to fix a bug in Safari, to avoid scrolling to empty screen
      slider.current.addEventListener('wheel', handleScrollSection);
    }

    return () => {
      slider.current?.removeEventListener('wheel', handleScrollSection);
    };
  }, []);

  return (
    <div
      ref={slider}
      id="labels"
      className={`section flex-center labels scroll-section ${styles.section}`}
    >
      <div className="section-container">
        <SliderSection
          sectionName="labels"
          slides={slides}
          sliderRef={slider}
          SlideComponent={SlideComponent}
          hasVideo
        />

        <Footer />
      </div>
    </div>
  );
};
